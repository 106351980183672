import React, { ReactNode } from 'react';
import { EPDModel } from 'services/EpdClient';
import { useUpdateContentDeclarationProduct, useUpdateEpdGeneralInformation } from 'services/api/mutations';
import { useEpdContentDeclarationProducts, useEpdProductInformation } from 'services/api/queries';
import styled from 'styled-components';
import {
  ExpandablePanel,
  ExpandablePanelContainer,
  ExpandablePanelHeaderName,
  FieldPanelFullWidth,
  HeaderCaptionSemiBold,
  StyledInput,
} from 'styles/v2/Styles.styled';
import {
  EPDContentDeclarationProductType,
  EPDDeclareContentProductModel,
  EPDGeneralInformationModel,
  EPDProductInformationModel,
  Option,
} from 'types/types';

import CompilerSelectComponent from '../epd-compiler-inputs/CompilerSelectComponent';
import WizardTextAreaComponent from '../epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from '../epd-wizard-inputs/WizardTextInput';
import TooltipHelpIcon from '../icons/TooltipHelpIcon';
import BestProductComponentsGrid from './grids/product-content/BestProductComponentsGrid';
import BestProductPackagingMaterialsGrid from './grids/product-content/BestProductPackagingMaterialsGrid';
import BestProductPanelContentGrid from './grids/product-content/BestProductPanelContentGrid';
import DangerMaterialsGrid from './grids/product-content/DangerMaterialsGrid';
import PackagingMaterialsGrid from './grids/product-content/PackagingMaterialsGrid';
import PanelContentGrid from './grids/product-content/PanelContentGrid';
import ProductComponentsGrid from './grids/product-content/ProductComponentsGrid';
import { ExpandableHelpBox } from '../help-boxes';
import { EPDProductType } from 'types/types';
import { YES_NO, YesNoDataSource } from './constants';
import CompilerSelectControlledComponent from '../epd-compiler-inputs/CompilerSelectControlledComponent';
import TooltipErrorIcon from '../icons/TooltipErrorIcon';
import BestProductDangerMaterialsGrid from './grids/product-content/BestProductDangerMaterialsGrid';

const ContentDeclarationTab: React.FunctionComponent<{
  epd?: EPDModel;
  epdVersionId: string;
  generalInformation: EPDGeneralInformationModel;
  validationState: any;
}> = ({ epd, epdVersionId, generalInformation, validationState }) => {
  const hasDangerMaterialsLookup = [
    { value: false, label: YES_NO.NO },
    { value: true, label: YES_NO.YES },
  ];

  const { errors, fieldsState } = validationState || {};
  const updateMutation = useUpdateEpdGeneralInformation(epdVersionId);
  const updateContentDeclarationProduct = useUpdateContentDeclarationProduct(epdVersionId);
  const contentDeclarationProducts = useEpdContentDeclarationProducts(epdVersionId).data;
  const averageProductContentDeclaration =
    contentDeclarationProducts?.find((x) => x.type === EPDContentDeclarationProductType.Average) ?? null;
  const bestProductContentDeclaration =
    contentDeclarationProducts?.find((x) => x.type === EPDContentDeclarationProductType.BestProduct) ?? null;

  const isPanelContentEnabled = epd?.cpcr?.name?.includes('c-PCR-014');

  const isBestCaseEnabled = !!contentDeclarationProducts?.find((x) => x.type === EPDContentDeclarationProductType.BestProduct)?.productDescription;

  const onChangeEpdGeneralInformation = async (propertyName: string, val: any) => {
    updateMutation.mutate({ epdId: epd?.id, versionId: epdVersionId, propertyName: propertyName, newValue: val });
  };

  const onChangeEpdContentDeclarationProduct = async (
    type: EPDContentDeclarationProductType,
    propertyName: string,
    val: any
  ) => {
    updateContentDeclarationProduct.mutate({
      type: type,
      versionId: epdVersionId,
      propertyName: propertyName,
      newValue: val,
    });
  };

  const headerTemplate = (options: any, header: ReactNode, tooltip?: string, tooltipPosition?: any | undefined) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';

    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          {header}
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
        </ExpandablePanelHeaderName>
        <button className={options.togglerClassName} onClick={options.onTogglerClick} style={{ margin: '0 0.5rem' }}>
          <span className={toggleIcon}></span>
        </button>
      </div>
    );
  };

  return (
    <>
      <FieldPanelFullWidth>
        {generalInformation?.productType === EPDProductType.Services && (
          <>
            <div style={{ display: 'flex', alignSelf: 'auto', alignItems: 'center' }}>
              <HeaderCaptionSemiBold className="white-space-nowrap">Declare content</HeaderCaptionSemiBold>
              {!generalInformation?.serviceIncludesProductInSystemBoundary && <TooltipErrorIcon content={'Required'} />}
            </div>
            <ExpandableHelpBox
              headerTemplate={(options) =>
                headerTemplate(options, <HelpBoldText>How to report “Declared content” values?</HelpBoldText>)
              }
              toggleable
            >
              <span>
                The gross weight of material in the content declaration shall cover 100% of one unit of product and its
                packaging. If there is more than 5% (post-consumer) recycled or biogenic content in the product, this shall
                be declared (if below 5%, this may be declared). If the share of biobased/recycled material is unknown, this
                part of the content declaration can be left out or be declared as 0% (a conservative estimate) or unknown.
              </span>
            </ExpandableHelpBox>
            <CompilerSelectControlledComponent
              label="Does the service include any physical products used within the defined system boundary?"
              tooltip="If the service involves a physical product that is used in the construction, maintenance, repair, replacement, refurbishment, or demolition of several construction works, the content of that product shall be declared in the content declaration"
              placeholder="Select"
              name="serviceIncludesProductInSystemBoundary"
              options={YesNoDataSource}
              value={YesNoDataSource.find(
                (item) => item.value === generalInformation?.serviceIncludesProductInSystemBoundary
              )}
              onChanged={onChangeEpdGeneralInformation}
              isClearable={false}
              required
              error={errors?.generalInformation?.serviceIncludesProductInSystemBoundary}
            />
            {generalInformation?.serviceIncludesProductInSystemBoundary === YES_NO.NO && (
              <WizardTextAreaComponent
                label="Explanation why content declaration is not relevant"
                placeholder="Type here"
                name="declarationIrrelevanceExplanation"
                value={generalInformation?.declarationIrrelevanceExplanation}
                onChanged={onChangeEpdGeneralInformation}
                required
                error={errors?.generalInformation?.declarationIrrelevanceExplanation}
              />
            )}
          </>
        )}
        {generalInformation?.epdClassification && generalInformation?.epdClassification !== 1 && (
          <WizardTextAreaComponent
            label="Content declaration of multiple products"
            name="productDescription"
            value={averageProductContentDeclaration?.productDescription}
            onChanged={(name: any, value: any) => {
              onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.Average, name, value);
            }}
            placeholder="Type here"
            required={true}
            state={fieldsState}
            tooltip="In EPDs of multiple products or sector EPDs, it shall be described in the content declaration section what the content declaration represents."
          />
        )}
        <ProductComponentsGrid
          epdVersionId={epd?.versionId!}
          serviceIncludesProductInSystemBoundary={generalInformation?.serviceIncludesProductInSystemBoundary}
          errors={errors?.productComponents}
        />
        <PackagingMaterialsGrid
          epdVersionId={epd?.versionId!}
          serviceIncludesProductInSystemBoundary={generalInformation?.serviceIncludesProductInSystemBoundary}
          errors={errors?.packagingMaterials}
        />
        {isPanelContentEnabled && <PanelContentGrid epdVersionId={epd?.versionId!} />}
      </FieldPanelFullWidth>
      <FieldPanelFullWidth>
        <CompilerSelectControlledComponent
          label="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
          tooltip="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
          name="hasDangerMaterials"
          options={hasDangerMaterialsLookup}
          value={hasDangerMaterialsLookup.find((x) => x.value == averageProductContentDeclaration?.hasDangerMaterials)}
          onChanged={(name: any, value: any) => {
            onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.Average, name, value);
          }}
          placeholder="Select..."
          required={true}
          error={errors?.generalInformation?.hasDangerMaterials}
        />
        {averageProductContentDeclaration?.hasDangerMaterials && (
          <DangerMaterialsGrid epdVersionId={epd?.versionId!} errors={errors?.dangerMaterials} />
        )}
      </FieldPanelFullWidth>
      {generalInformation?.epdClassification && generalInformation?.epdClassification !== 1 && (
        <ExpandablePanelMarginContainer>
          <ExpandablePanelMultiContainer
            headerTemplate={(options) =>
              headerTemplate(
                options,
                <HeaderCaptionSemiBold>Content declaration of scenario “best-case product”</HeaderCaptionSemiBold>
              )
            }
            toggleable
            collapsed
          >
            <FieldPanelFullWidth>
              <WizardTextInputComponent
                label="Best-case product"
                tooltip="The “best-case product” is defined as the product with the lowest GWP-GHG results."
                onChanged={(name: any, value: any) => {
                  onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.BestProduct, name, value);
                }}
                placeholder="Type here"
                name="productDescription"
                value={
                  contentDeclarationProducts?.find((product) => product.type == EPDContentDeclarationProductType.BestProduct)
                    ?.productDescription
                }
              />
            </FieldPanelFullWidth>
            {isBestCaseEnabled && (
              <FieldPanelFullWidth>
                <BestProductComponentsGrid epdVersionId={epd?.versionId!} />
                <BestProductPackagingMaterialsGrid epdVersionId={epd?.versionId!} />
                {isPanelContentEnabled && <BestProductPanelContentGrid epdVersionId={epd?.versionId!} />}
                <CompilerSelectControlledComponent
                  label="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
                  tooltip="The product contains substances in the list of SVHC that constitute more than 0.1% of its weight"
                  name="hasDangerMaterials"
                  options={hasDangerMaterialsLookup}
                  value={hasDangerMaterialsLookup.find((x) => x.value == bestProductContentDeclaration?.hasDangerMaterials)}
                  onChanged={(name: any, value: any) => {
                    onChangeEpdContentDeclarationProduct(EPDContentDeclarationProductType.BestProduct, name, value);
                  }}
                  placeholder="Select..."
                  error={errors?.generalInformation?.hasDangerMaterials}
                />
                {bestProductContentDeclaration?.hasDangerMaterials && (
                  <BestProductDangerMaterialsGrid epdVersionId={epd?.versionId!} errors={errors} />
                )}
              </FieldPanelFullWidth>
            )}
          </ExpandablePanelMultiContainer>
        </ExpandablePanelMarginContainer>
      )}
    </>
  );
};

const ExpandablePanelMarginContainer = styled(ExpandablePanelContainer)`
  box-shadow: none;
`;

const ExpandablePanelMultiContainer = styled(ExpandablePanel)`
  .p-panel-content {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100%;
    display: grid;
  }
  ${StyledInput} {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
  && textarea {
    color: ${(props) => props.theme.colors.primaryBlack};
  }
`;

const HelpBoldText = styled.span`
  font-weight: 600;
`;

export default ContentDeclarationTab;

import {
  ActionState,
  EPDStatus,
  FileModel,
  FileModelPlus,
  GeographicalScopeModel,
  LicenseType,
  PCRModel,
  PreverifiedTool,
  PreverifiedToolVersion,
  ProductClassificationModel,
  Subtype,
  VerificationType,
  VersionStateType,
  VisibilityType,
} from 'services/EpdClient';

export type Option = {
  label: string;
  value: string;
};

export type UserOptionModel = {
  label: string;
  value: string;
  userName: string;
};

export type OptionNameCode = {
  name: string;
  code: string;
};

export type ProductComponent = {
  id?: string;
  epdVersionId?: string;
  name?: string;
  weight?: number;
  finalWeightPercent?: number;
  biogenicWeightPercent?: number;
  biogenicCarbonWeight?: number;
  declareContentType?: EPDContentDeclarationProductType;
  biogenicCO2Weight?: number;
};

export type LCAStage<T> = {
  a1?: T;
  a2?: T;
  a3?: T;
  a1A2A3?: T;
  a4?: T;
  a5?: T;
  b1?: T;
  b2?: T;
  b3?: T;
  b4?: T;
  b5?: T;
  b6?: T;
  b7?: T;
  c1?: T;
  c2?: T;
  c3?: T;
  c4?: T;
  d?: T;
};

export type LcaResultsReadModel = {
  lcAs?: FileModel[];
  lcaResults: LcaResultModel[] | undefined;
};

export type LcaResultModel = LCAStage<string> & {
  id?: string | null;
  epdVersionId: string | null;
  indicatorGroupCode?: string;
  indicatorId?: string;
  indicatorName?: string;
  impactCategory?: string;
  unit?: string;
};

export type CpcrSpecificTableModel = {
  ord: number;
  tableCode: string;
  tableTitle: string;
  isFixed: boolean;
  tableDescription?: string;
};

export type CpcrSpecificDataModel = {
  tables: CpcrSpecificTableModel[];
  columns: any[];
  data: any[];
};

export type ProductPackagingMaterial = {
  id?: string;
  epdVersionId?: string;
  productId?: string | null;
  name?: string;
  weight?: number;
  weightPercent?: number;
  biogenicCarbonWeight?: number;
  biogenicCO2Weight?: number;
  declareContentType?: EPDContentDeclarationProductType;
};

export type ProductPanelContent = {
  id?: string;
  epdVersionId?: string;
  productId?: string | null;
  name?: string;
  weight?: number;
  weightPercent?: number;
  biogenicCarbonWeight?: number;
  biogenicCO2Weight?: number;
  declareContentType?: EPDContentDeclarationProductType;
};

export type ProductDangerMaterial = {
  id?: string;
  epdVersionId?: string;
  dangerMaterialLookupId?: string;
  name?: string;
  ecNo?: string;
  casNo?: string;
  weightPercent?: number;
  declareContentType?: EPDContentDeclarationProductType;
};

export type DangerMaterialLookup = {
  id: string;
  name: string;
};

export type ElectricityDataModel = {
  epdVersionId?: string;
  electricityMix?: string;
  climateImpact?: number;
  energySourceModel?: EnergySourceModel[];
};

export type EnergySourceModel = {
  id?: string;
  source?: string;
  value?: number | undefined;
};

export type DeclarationOfCO2AssumptionModel = {
  assumptions?: string;
  isSelected: boolean;
};

export type DeclarationOfCO2AssumptionModelWithParams = {
  epdVersionId: string;
  nameId: string;
  assumptions?: string;
  isSelected: boolean;
};

export type DeclarationOfCO2Model = {
  epdVersionId?: string;
  nameId: string;
  name?: string;
  assumptions?: string;
  isSelected: boolean;
};

export type CO2UptakeAssociationModelWithParams = {
  epdVersionId: string;
  id?: string;
  association?: string;
};

export type CO2UptakeAssociationModel = {
  id?: string;
  association?: string;
};

export type ScrapInputModel = {
  id?: string;
  epdVersionId?: string;
  name?: string;
  value?: number;
};

export type ShareOfTotalScrapInputModel = {
  id?: string;
  epdVersionId?: string;
  sharePercent?: number;
};

export type ScenarioModuleReadModel = {
  defaultScenarioModuleDescription?: string;
  scenarioModuleItems: ScenarioItemModel[];
};

export type ScenarioItemModel = IVersionedEntity & {
  name?: string;
  description?: string;
  value?: string;
  isDefaultScenario: boolean;
  enabledRSL?: boolean;
};

export type SystemBoundaryModel = IVersionedEntity & {
  specificDataUsed?: number;
  variationProducts?: number;
  variationSites?: number;
  description?: number;
  hasExcludedModules?: boolean;
  excludedModulesJustification?: string;
  processFlowDescription?: string;
  processFlowImages: FileModelPlus[];
};

export type SystemBoundaryGridModel = {
  epdVersionId?: string;
  declaredModules: LCAStage<boolean | undefined | null>;
  declaredModulesGeography: LCAStage<string>;
};

export type ScenarioModuleItem = {
  id: string;
  scenarioId: string;
  description?: string | undefined | null;
  value?: string | undefined | null;
  unitId?: string | undefined | null;
  placeholder?: string | undefined | null;
  unitRequired?: boolean | undefined | null;
  mandatory?: boolean | undefined | null;
};

export type ScenarioModuleRequest = {
  epdVersionId: string;
  scenarioId: string;
  module: string;
};

export type ScenarioModuleDefaultDescriptionModel = ScenarioModuleRequest & {
  defaultDescription: string;
};

export type TransportationPerformanceModel = {
  id?: string;
  epdVersionId?: string;
  transportationAverageCarLoad: string | undefined;
  transportationPerformance: string | undefined;
  transportationServiceLifeDistance: string | undefined;
};

export type DictionaryValue = {
  id: string;
  dictionaryCode: string;
  key: number;
  value: string;
  code: string;
  description: string;
};

export type DictionaryCatalogItem = {
  id?: string;
  name: string;
  code: string;
  description: string;
};

export type DictionaryValues = DictionaryValue[];
export type DictionaryCatalog = DictionaryCatalogItem[];
export type LCASpecificationModel = {
  id: string;
  epdVersionId?: string;
  shouldDeclarationsBeFetched: boolean;
  shouldTransportationPerformanceBeFetched: boolean;
  epdBasedOnUnit?: EPDBasedOnUnitEnum;
  functionalUnitDescription?: string;
  scrapInputsContributionLevel?: ScrapInputsContributionLevelEnum;
  lcaSoftware?: string;
  lcaSoftwareVersion?: string;
  lcaBasedDescription?: string;
  characterisationMethods?: string;
  technologyDescription?: string;
  softwareName?: string;
  softwareVersion?: string;
  infrastructureOrGoodsUpstreamIncluding?: string;
  infrastructureOrGoodsContributionLevel?: string;
  descriptionOfInfrastructureAndGoods?: string;
};

export type ReferenceFlowModel = {
  epdVersionId?: string;
  productFlowName: string;
  unitId: string;
  value: number;
  errors: any;
};

export type ConversionFactorModel = {
  epdVersionId?: string;
  unitId: string;
  value: number;
  errors: any;
};

export type EPDDataSourceModel = {
  id: string;
  epdVersionId: string;
  dataSourceId?: string;
  dataSourceVersionId?: string;
  isMain: boolean;
};

export type DataSourceOptionModel = {
  id: string;
  name?: string;
  dataSourceVersions?: Option[];
};

export type LCASoftwareOptionModel = {
  id: string;
  name?: string;
  versionRequired?: boolean;
  lcaSoftwareVersions?: Option[];
};

export enum EPDProductType {
  Goods = 'Goods',
  Services = 'Services',
}

export enum EPDBasedOnUnitEnum {
  DeclaredUnit = 'DeclaredUnit',
  FunctionalUnit = 'FunctionalUnit',
}

export enum ScrapInputsContributionLevelEnum {
  MorePermissiblePercent = 'MorePermissiblePercent',
  LessPermissiblePercent = 'LessPermissiblePercent',
}

export type TagModel = {
  id: string;
  name: string;
};

export interface VerificationViewModel {
  name: string;
  verificationReports: FileModel[];
  approvalDate: Date;
  validityDate: Date;
}

export interface EPDActionsStatesModel {
  isMandatoryComplete?: boolean;
  isEditorialUpdate?: boolean;
  deleteDraft?: ActionState;
  cancelUpdate?: ActionState;
  startVerification?: ActionState;
  cancelVerification?: ActionState;
  register?: ActionState;
  deregister?: ActionState;
  unlock?: ActionState;
  cancelDeregistration?: ActionState;
  approve?: ActionState;
  publish?: ActionState;
  unpublish?: ActionState;
  needsVerification?: ActionState;
  registerEditorial?: ActionState;
  editorialUpdating?: ActionState;
  approveEditorial?: ActionState;
  licenseeAdminVerification?: ActionState;
  status?: EPDStatus;
  statusMessage?: string | undefined;
  requiredActionMessage?: string | undefined;
}

export interface IVersionedEntity {
  id: string;
  epdVersionId: string;
}

export type EPDGeneralInformationModel = IVersionedEntity & {
  versionNumber: number;
  registrationNumber: string;
  declarationName: string;
  issueDate: Date;
  expirationDate: Date;
  productType: EPDProductType;
  epdClassification: number | string;
  collections?: Option[];
  developers?: Option[];
  errors: any;
  isCompiler: boolean;
  shortIdentificationNumber: string;
  fullIdentificationNumber: string;
  epdDefinitionModel: EPDDefinitionModel;
  serviceIncludesProductInSystemBoundary?: string;
  declarationIrrelevanceExplanation?: string;
};

export type EPDDefinitionModel = IVersionedEntity & {
  useAdvice: string;
  subtype?: Subtype;
  tags?: Option[];
  versionNumber: number;
  registrationNumber: string;
  declarationName: string;
  productType: EPDProductType;
  productName?: string;
  collections?: Option[];
  epdClassification: number | string;
  declaredStandards?: string;
};

export type EPDDeclareContentProductModel = {
  id?: string;
  epdVersionId?: string;
  productDescription?: string;
  type?: EPDContentDeclarationProductType;
  hasDangerMaterials?: boolean;
};

export interface UpdateEPDDeclareContentProductModel {
  type?: EPDContentDeclarationProductType; 
  versionId?: string;
  propertyName?: string | undefined;
  newValue?: any | undefined;
}

export type EPDOrganizationInformationModel = IVersionedEntity & {
  contactPersonEmail?: string;
  contactPersonName?: string;
  description?: string;
  organizationImages?: FileModelPlus[];
};

export type EPDProductInformationModel = {
  id?: string;
  epdVersionId: string;
  productName?: string;
  productIdentification?: string;
  productDescription?: string;
  productInformationExternal?: string;
  productionSites?: FoundationAddressModel[];
  productGeographicalScopes?: GeographicalScopeModel[];
  productClassifications?: ProductClassificationModel[];
  geographicalScopeDescription?: string;
  gtin?: string;
  uncpcId?: string;
  unitValue?: string;
  unitType?: string | number;
  productDetailsImages?: FileModelPlus[];
  manufacturingOrServiceProvisionDescription?: string;
};

export type UpdateEPDProductInformationPropertyModel = {
  productId?: string;
  versionId?: string;
  propertyName?: string | undefined;
  newValue?: string | undefined;
};

export type CreateEPDProductionSiteAddressModel = {
  productId?: string;
  versionId?: string;
};

export type FoundationAddressModel = {
  id: string;
  countryId: string;
  addressLine: string;
  addressLineSecond?: string;
  city: string;
  zipCode: string;
};

export type EPDOrganizationInformationAddressModel = IVersionedEntity & FoundationAddressModel;

export type EPDCoverPageModel = {
  id: string;
  versionId: string;
  productImages?: FileModelPlus[];
  companyLogoImages?: FileModelPlus[];
};

export type EPDAdditionalInformationModel = IVersionedEntity & {
  references?: string;
  environmental?: string;
  conversion?: string;
  dangerous?: string;
  economic?: string;
};

export type ProductCategoryModel = {
  id?: string;
  title?: string | undefined;
  productCategories?: ProductCategoryModel[] | undefined;
};

export type PCRTypeModel = {
  pcr: PCRModel;
  cpcr: PCRModel;
  cpcrProductCategory?: ProductCategoryModel | undefined;
  cpcrProductCategories?: ProductCategoryModel[] | undefined;
};

export type EPDPDFModel = unknown & {
  generalInformation: any;
  coverPageInformation: any;
  organizationInformation: EPDOrganizationInformationModel & {
    name: string;
    logo: any;
    address: EPDOrganizationInformationAddressModel & {
      countryName: string;
    };
  };
  companyInformation: any;
  additionalInformation: any;
  pcrInformation: PCRTypeModel;
  countries: any;
  lcaSpecification: LCASpecificationModel;
  contentDeclarationProducts: EPDDeclareContentProductModel[];
};

export enum VerifierType {
  Missing = 'Missing',
  Body = 'Accredited Certification Body',
  Individual = 'Approved Individual Verifier',
}

export enum EPDContentDeclarationProductType {
  Average = 'AverageProduct',
  BestProduct = 'BestProduct',
  WorstProduct = 'WorstProduct',
}

export type LicenseTypeOption = {
  label: LicenseType;
  value: LicenseType | undefined;
};

export type AddressModel = {
  line1?: string | undefined;
  line2?: string | undefined;
  line3?: string | undefined;
  line4?: string | undefined;
  city?: string | undefined;
  country?: string | undefined;
  countryId?: string | undefined;
};

export type EPDVerifierModel = {
  id: string;
  verifierType?: VerifierType;
  contactUserId: string;
  contactName: string;
  companyId: string;
  companyName: string;
  address?: AddressModel | undefined;
  website?: string | undefined;
  information?: string | undefined;
  email?: string | undefined;
  phoneNumber?: string | undefined;
  displayName?: string | undefined;
  logotypeFileId?: string | undefined;
  isExternalMembership?: string | undefined;
};

export type EPDDeveloperModel = {
  contactUserId?: string;
  contactName?: string | undefined;
  contactEmail?: string | undefined;
  companyId?: string;
  companyName?: string | undefined;
  address?: AddressModel | undefined;
  website?: string | undefined;
  logotypeFileId?: string | undefined;
  requestSentAt?: Date | undefined;
};

export type LcaSupportModel = {
  epdDevelopers: EPDDeveloperModel[] | undefined;
};

export type ThirdPartyVerificationModel = {
  epdVerifier?: EPDVerifierModel | undefined;
  preverifiedTool: PreverifiedTool | undefined;
  preverifiedToolVersion: PreverifiedToolVersion | undefined;
  preverifiedToolVerifierAccreditedBy: string | undefined;
  verificationType: VerificationType | undefined;
  verificationTypeDataSource: VerificationType[];
  processCertificateIssuedBy?: EPDVerifierModel | undefined;
  processCertificates?: FileModel[] | undefined;
  followUpInvolvesThirdPartyVerifier: string | undefined;
  preVerifiedToolDataSource: PreverifiedTool[] | undefined;
};

export type EPDVerifierCompanyModel = {
  id?: string;
  name?: string | undefined;
  information?: string | undefined;
  verifierType?: VerifierType;
  email?: string | undefined;
  phoneNumber?: string | undefined;
  logotypeFileId?: string | undefined;
  successOperationText?: string | undefined;
  errorText?: string | undefined;
  requestSentAt?: Date | undefined;
};

export type ConnectDeveloperModel = {
  epdId: string;
  membershipId: string;
};

export type ConnectVerifierModel = {
  epdId: string;
  membershipId: string;
};

export type RequestCollaborationModel = {
  epdVersionId: string;
  roleId?: string;
  companyId?: string;
  verificationType: VerificationType;
};

export type EPDDetails = {
  generalInformation: EPDGeneralInformationModel;
  coverPage: any;
  lca: {
    specification: {
      basedOnUnit: string;
      referenceFlow: {
        productFlowName: '';
        unit: '';
        value: '';
      };
      transportationPerformance: {
        avgCarLoad: 0.1;
        distance: 1.2;
        transportationPerformance: 1.2;
      };
      lcaBasedInformation: {
        referenceYear: ''; // this could be from-to, under discussion
        referenceToTheMainDatabase: '';
        lcaSoftware: '';
        softwareVersion: '';
        noTool: false;
        preverifiedTool: '';
        toolVersion: '';
        additionalInformationUnderlying: '';
        characterizationFactors: '';
      };
      electricityData: {};
      contributionScrapInputs: {};
    };
    scopeAndBoundary: {
      systemBoundary: SystemBoundaryModel;
      declaredModules: {
        modules: LCAStage<boolean | undefined>;
        geography: LCAStage<string>;
        specificDataUsed: string;
        variationProducts: string;
        variationSites: string;
      };
    };
  };
};

export type AddEPDFileModel = {
  epdId?: string;
  epdProductInformationId?: string;
  propertyName?: string | undefined;
  file?: File | undefined;
};

export type AddEPDPropertyModel = {
  epdId: string;
  versionId: string;
  propertyName: string;
  newValue: any;
};

import { Column } from 'primereact/column';
import React, { useMemo } from 'react';
import { useEpdProductDangerMaterials } from 'services/api/queries';
import { HeaderCaptionSemiBold } from 'styles/v2/Styles.styled';

import { DataTableStyled } from '../StyledGrid';
import { EPDContentDeclarationProductType } from 'types/types';

const DangerMaterialsGridPdf: React.FunctionComponent<{ epdVersionId: string }> = ({ epdVersionId }) => {
  const rows = useEpdProductDangerMaterials(epdVersionId!, EPDContentDeclarationProductType.Average).data;

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <HeaderCaptionSemiBold className="white-space-nowrap">Hazardous and toxic Substances</HeaderCaptionSemiBold>
    </div>
  );

  return (
    <DataTableStyled
      header={header}
      value={rows}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      editMode="cell"
    >
      <Column field="name" header="Hazardous/Toxic substances from the candidate list" style={{ maxWidth: '16rem' }} />
      <Column field="ecNo" header="EC No." style={{ minWidth: '6rem' }} />
      <Column field="casNo" header="Cas. No" style={{ minWidth: '6rem' }} />
      <Column field="weightPercent" header={'Weight per functional or declared unit %'} style={{ maxWidth: '10rem' }} />
    </DataTableStyled>
  );
};

export default DangerMaterialsGridPdf;

import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import { Column, ColumnBodyOptions, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import React, { useState } from 'react';
import {
  useCreateEpdProductDangerMaterial,
  useDeleteProductDangerMaterial,
  useUpdateEpdProductDangerMaterial,
} from 'services/api/mutations';
import { useDangerMaterialsLookup, useEpdBestProductDangerMaterials } from 'services/api/queries';
import { EPDContentDeclarationProductType, ProductDangerMaterial } from 'types/types';

import { cellNumberEditor } from '../GridCellEditors';
import { headerTemplate, mandatoryBodyTemplate } from '../GridService';
import { DataTableStyled, GridButton } from '../StyledGrid';

const BestProductDangerMaterialsGrid: React.FunctionComponent<{ epdVersionId: string; errors?: any }> = ({ epdVersionId, errors }) => {
  const [selectedRows, setSelectedRows] = useState<ProductDangerMaterial[] | null>(null);

  const dangerMaterialsLookup = useDangerMaterialsLookup().data;

  const rows = useEpdBestProductDangerMaterials(epdVersionId, EPDContentDeclarationProductType.BestProduct).data;
  const createMutation = useCreateEpdProductDangerMaterial(EPDContentDeclarationProductType.BestProduct);
  const updateMutation = useUpdateEpdProductDangerMaterial(epdVersionId, EPDContentDeclarationProductType.BestProduct);
  const deleteMutation = useDeleteProductDangerMaterial();

  const handleAddRow = () => {
    createMutation.mutate({ epdVersionId, weightPercent: 0, declareContentType: EPDContentDeclarationProductType.BestProduct  });
  };

  const handleDeleteSelected = async () => {
    if (!selectedRows) return;
    await Promise.all(selectedRows.map((x) => deleteMutation.mutateAsync(x.id!)));
    setSelectedRows(null);
  };

  const isTableFilledIn = rows?.every((r) => r.name) && rows?.every((r) => r.weightPercent);

  const isTableDisabled = !selectedRows?.length || deleteMutation.isPending;
  const header = headerTemplate(
    isTableFilledIn,
    'hazardous and toxic Substances',
    handleAddRow,
    handleDeleteSelected,
    isTableDisabled,
    errors?.general || (errors && Object.keys(errors).length > 0 && 'Has errors')
  );

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;
    updateMutation.mutate(rowData);
  };

  const selectEditor = (options: ColumnEditorOptions, lookupIdField: string) => {
    return (
      <Dropdown
        filter
        style={{ width: '100%', height: '38px', maxWidth: '20rem' }}
        value={options.rowData[lookupIdField] || options.value}
        optionLabel="name"
        optionValue="id"
        options={dangerMaterialsLookup}
        onChange={(e: DropdownChangeEvent) => {
          options.editorCallback?.(e.value);

          let { rowData, field } = options;
          rowData[lookupIdField || field] = e.value;
          updateMutation.mutate(rowData);
        }}
        itemTemplate={(option) => {
          return <div style={{ maxWidth: '40rem', wordWrap: 'break-word', fontSize: '12px' }}>{option.name}</div>;
        }}
      />
    );
  };

  const mandatoryCellTemplate = (value: ProductDangerMaterial, options: ColumnBodyOptions) =>
    mandatoryBodyTemplate((value as any)?.[options?.field], errors?.[`row[${options?.rowIndex}]`]?.[options?.field]);

  return (
    <DataTableStyled
      header={header}
      value={rows}
      selectionMode="checkbox"
      selection={selectedRows!}
      onSelectionChange={(e) => setSelectedRows(e.value as ProductDangerMaterial[])}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      editMode="cell"
    >
      <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
      <Column
        field="name"
        header="Hazardous/Toxic substances from the candidate list"
        editor={(options) => selectEditor(options, 'dangerMaterialLookupId')}
        body={mandatoryCellTemplate}
      />
      <Column field="ecNo" header="EC No." style={{ minWidth: '8rem' }} />
      <Column field="casNo" header="Cas. No" style={{ minWidth: '8rem' }} />
      <Column
        field="weightPercent"
        header={'Weight per functional or declared unit %'}
        editor={(options) => cellNumberEditor(options)}
        onCellEditComplete={onCellEditComplete}
        style={{ maxWidth: '10rem' }}
        body={mandatoryCellTemplate}
      />
    </DataTableStyled>
  );
};

export default BestProductDangerMaterialsGrid;
